<template>
  <Transition
    v-if="withTransition"
    name="fade"
    mode="out-in"
    @after-leave="onTransitionEnd"
  >
    <!-- eslint-disable-next-line -->
    <template #default>
      <div v-if="isLoading" :class="loadingContainerClass" class="c-loading">
        <img :style="{ width: `${iconWidth}px` }" :src="loadingGif" />
        <span>
          {{ label }}
        </span>
        <span class="c-loading__sub-label">
          {{ subLabel }}
        </span>
      </div>
      <slot v-else />
      <slot name="modal" />
    </template>
  </Transition>
  <div v-else>
    <div v-if="isLoading" :class="loadingContainerClass" class="c-loading">
      <img :style="{ width: `${iconWidth}px` }" :src="loadingGif" />
      <span>
        {{ label }}
      </span>
      <span class="c-loading__sub-label">
        {{ subLabel }}
      </span>
    </div>
    <slot v-else />
    <slot name="modal" />
  </div>
</template>

<script setup lang="ts">
import loadingGif from '../assets/loading.gif';

interface Props {
  iconWidth?: number;
  isLoading?: boolean;
  label?: string;
  loadingContainerClass?: string;
  subLabel?: string;
  withTransition?: boolean;
}

withDefaults(defineProps<Props>(), {
  iconWidth: 64,
  isLoading: false,
  label: '',
  loadingContainerClass: '',
  subLabel: '',
  withTransition: true,
});

const emit = defineEmits(['transition-ended']);
function onTransitionEnd() {
  emit('transition-ended');
}
</script>
<style lang="pcss" scoped>
.c-loading {
  @apply text-brand-navy;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.c-loading__sub-label {
  @apply text-neutral-text-strong;
  font-size: 12px;
}
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-150 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}

.c-loading__label {
  @apply text-m font-bold text-neutral-text-strong;
}
</style>
